<template>
  <section>
    <b-row>
      <b-col>
        <BrunaForm ref="nuevoContratoRef" v-bind:fields="this.nuevo_contrato.fields" :card="this.nuevo_contrato.card"
          @change="nuevaContratoChange"></BrunaForm>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button variant="primary" class="mt-4 float-right" @click="save" size="lg">Guardar</b-button>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BFormFile,
  BCardTitle,
  BCardText,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BFormDatepicker,
  BTable,
  BTableSimple,
  BTbody,
  BThead,
  BTfoot,
  BTr,
  BTd,
  BTh,
  BTab,
  VBPopover,
  VBTooltip,
} from "bootstrap-vue";
import Screenshot from "@/views/clients/fiordo/components/Screenshot";
import AgGridTable from "@/views/brunacomponents/ag-grid-table/AgGridTable.vue";
import useApiServices from "@/services/useApiServices.js";
import axios from "@axios";
import RepeatStatistics from "@/views/clients/fiordo/components/RepeatStatistics";
import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  name: "Ingreso Contratos",
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormFile,
    BCardTitle,
    BCardText,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BFormDatepicker,
    BTable,
    BTableSimple,
    BTbody,
    BThead,
    BTfoot,
    BTr,
    BTd,
    BTh,
    BTab,
    Screenshot,
    VBPopover,
    VBTooltip,
    AgGridTable,
    RepeatStatistics,
    BrunaForm,
    ToastificationContent,
  },
  data() {
    let activeTabName = this.$route.params.active_tab_name;
    let tabIndex = 0;

    if (activeTabName == "Ingreso Contratos") {
      tabIndex = 0;
    }

    if (activeTabName == "Histórico Contratos") {
      tabIndex = 1;
    }

    if (activeTabName == "Nuevo Contrato") {
      tabIndex = 2;
    }
    return {
      tabIndex: tabIndex,
      config: useApiServices.ingresoContratosConfig,
      data: useApiServices.ingresoContratosData,
      itemsData: [],
      nuevo_contrato: {
        card: {
          title: "Ingresar Nueva Demanda",
          subtitle: "",
          tooltip: "La asignación de toneladas a producir mensual se calcula automáticamente dividiendo en partes iguales las toneladas totales y los meses totales de duración del contrato",
        },
        fields: [
          {
            label: "Status",
            type: "select",
            id: "status",
            rules: "required",
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "Activo", text: "Activo" },
              { value: "Inactivo", text: "Inactivo" },
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
          },
          {
            label: "Emisíon",
            type: "datepicker",
            id: "emision",
            rules: "required",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
            placeholder: "Emisíon"
          },
          {
            label: "Contrato",
            type: "input",
            id: "contrato",
            rules: "required",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
            placeholder: "Contrato",
            description: "Código único de contrato"
          },
          {
            label: "Tons",
            type: "input",
            id: "tons",
            rules: "required|numeric2",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
            placeholder: "Tons",
            description: "Toneladas totales de contrato"
          },
          {
            label: "Prod SKU",
            type: "select",
            id: "prod_sku",
            rules: "required",
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "Salmo Pet Plus", text: "Salmo Pet Plus" },
              { value: "Salmo Pet", text: "Salmo Pet" },
              { value: "Salmo Pet-ASC", text: "Salmo Pet-ASC" },
            ],

            
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
            placeholder: "Prod SKU"
          },
          {
            label: "Cliente",
            type: "select",
            id: "cliente",
            rules: "required",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "IQI", text: "IQI" },
              { value: "Transcontinental", text: "Transcontinental" },
              { value: "Fiordo Corp", text: "Fiordo Corp" },
              { value: "Inproquisa", text: "Inproquisa" },
              { value: "Tectron", text: "Tectron" },
              { value: "Neovia", text: "Neovia" },
              { value: "Alisul", text: "Alisul" },
              { value: "Vitamix", text: "Vitamix" },
              { value: "Grandfood", text: "Grandfood" },
              { value: "Teampower", text: "Teampower" },
              { value: "Nestle", text: "Nestle" },
            ],
            value: null,
            placeholder: "Cliente"
          },
          {
            label: "Destino",
            type: "select",
            id: "destino",
            rules: "required",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "Holanda", text: "Holanda" },
              { value: "Canada", text: "Canada" },
              { value: "USA", text: "USA" },
              { value: "Inglaterra", text: "Inglaterra" },
              { value: "España", text: "España" },
              { value: "Brasil", text: "Brasil" },
              { value: "China", text: "China" },
              { value: "Argentina", text: "Argentina" },
            ],
            value: null,
            placeholder: "Destino"
          },
          {
            label: "Embarque Inicio",
            type: "datepicker",
            id: "embarque_inicio",
            rules: "required",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
            placeholder: "Embarque Inicio",
            description: "Fecha de comienzo de entregas parciales"
          },
          {
            label: "Embarque Fin",
            type: "datepicker",
            id: "embarque_fin",
            rules: "required",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
            placeholder: "Embarque Fin",
            description: "Fecha de fin de entregas parciales"
          },
          {
            label: "Materia Prima",
            type: "select",
            id: "materia_prima",
            rules: "required",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "SR", text: "SR" },
              { value: "CR", text: "CR" },
            ],
            value: null,
            placeholder: "Calidad"
          },
          {
            label: "PAC",
            type: "select",
            id: "pac",
            rules: "required",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "Si", text: "Si" },
              { value: "No", text: "No" },
            ],            
            value: null,
            placeholder: "PAC"
          },
          {
            label: "Prot(%)min",
            type: "input",
            id: "prot_min",
            rules: "required|numeric2",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
            placeholder: "Prot(%)min"
          },
          {
            label: "Prot(%)max",
            type: "input",
            id: "prot_max",
            rules: "required|numeric2",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
            placeholder: "Prot(%)max"
          },
          {
            label: "Grasa(%)max",
            type: "input",
            id: "grasa_max",
            rules: "required|numeric2",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
            placeholder: "Grasa(%)max"
          },
          {
            label: "Hum(%)max",
            type: "input",
            id: "hum_max",
            rules: "required|numeric2",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
            placeholder: "Hum(%)max"
          },
          {
            label: "Hum(%)min",
            type: "input",
            id: "hum_min",
            rules: "required|numeric2",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
            placeholder: "Hum(%)min"
          },
          {
            label: "Cen(%)máx",
            type: "input",
            id: "cen_max",
            rules: "required|numeric2",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
            placeholder: "Cen(%)máx"
          },
          {
            label: "Sal(%)máx",
            type: "input",
            id: "sal_max",
            rules: "required|numeric2",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
            placeholder: "Sal(%)máx"
          },
          {
            label: "Arena(%)máx",
            type: "input",
            id: "arena_max",
            rules: "required|numeric2",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
            placeholder: "Arena(%)máx"
          },
          {
            label: "FFA máx",
            type: "input",
            id: "ffa_max",
            rules: "required|numeric2",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
            placeholder: "FFA máx"
          },
          {
            label: "TVN máx",
            type: "input",
            id: "tvn_max",
            rules: "required|numeric2",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
            placeholder: "TVN máx"
          },
          {
            label: "Hist máx",
            type: "input",
            id: "hist_max",
            rules: "required|numeric2",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
            placeholder: "Hist máx"
          },
          {
            label: "Perox máx",
            type: "input",
            id: "perox_max",
            rules: "required|numeric2",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
            placeholder: "Perox máx"
          },

          {
            label: "Antioxidante",
            type: "select",
            id: "antioxidante",
            rules: "required",
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "BHA/BHT", text: "BHA/BHT" },
              { value: "Petox", text: "Petox" },
              { value: "Naturox", text: "Naturox" },
              { value: "TCF", text: "TCF" },
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
          },

          {
            label: "Especie",
            type: "input",
            id: "especie",
            rules: "required",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
            placeholder: "Especie"
          },

          {
            label: "Envase",
            type: "input",
            id: "envase",
            rules: "required",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
            placeholder: "Envase"
          },          


          {
            label: "Planta",
            type: "input",
            id: "planta",
            rules: "required",
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
            placeholder: "Planta"
          },   
          
          {
            label: "Restriccion",
            type: "select",
            id: "restriccion",
            rules: "required",
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "Si", text: "Si" },
              { value: "No", text: "No" },
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
          },

          
          {
            label: "Mortalidad",
            type: "select",
            id: "mortalidad",
            rules: "required",
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "Si", text: "Si" },
              { value: "No", text: "No" },
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3,
            },
            value: null,
          },          
          

        ],
      },
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },
  // Repeat Statistics Cards
  mounted() {
    axios
      .get(useApiServices.ingresoContratosStatistics)
      .then((response) => {
        this.itemsData = response.data;
      })
      .catch((error) => { });
  },
  //Form methods
  methods: {
    formatData() {
      return {
        nuevo_contrato: this.nuevo_contrato.fields.reduce(
          (acumulador, field) => {
            acumulador[field.id] = field.value;
            return acumulador;
          },
          {}
        ),
      };
    },
    createNovoContrato() {
      useApiServices
        .postIngresoContratosCreate(this.formatData(), this)
        .then((response) => {
          this.$emit("nuevo-contrato-agregado");

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Carga Exitosa`,
              icon: "UploadIcon",
              variant: "success",
              html: `El contrato se ha creado exitosamente.`,
            },
          });
          // setTimeout(() => {
          //     window.location.reload();
          // }, 2000);
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ocurrio un Error`,
              icon: "UploadIcon",
              variant: "danger",
              html: `Ha ocurrido un error al guardar el nuevo contrato.`,
            },
          });
        });
    },

    async save() {
      console.log(JSON.stringify(this.formatData(), null, 2));
      let self = this;
      let verification = await this.$refs.nuevoContratoRef.validate()
      if (!verification) {
        this.$toast({
          component: ToastificationContent,
          position: "top-",
          props: {
            title: `Ocurrio un Error`,
            icon: "UploadIcon",
            variant: "danger",
            html: `Ha ocurrido un error al guardar el nuevo contrato.`,
          },
        });
        return 0
      }
      self.createNovoContrato();
    },

    nuevaContratoChange(data) { },
  },
};
</script>
<style lang="scss">

</style>